.container {
  max-width: 1440px;
  padding: 0 20px;
  margin: 60px auto 0;

  @media (min-width: 1024px) {
    margin: 143px auto 0;
  }

  @media (min-width: 1550px) {
    max-width: 1300px;
  }
}

.wrapper {
  //max-width: 1046px;
}

.wrapperTitle {
  margin-bottom: 34px;
  text-align: center;

  @media (min-width: 1024px) {
    margin-bottom: 60px;
    text-align: left;
    margin-left: 200px;
  }
}

.subText {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 14px;
  line-height: 140%;
  color: #73818A;

  @media (min-width: 1024px) {
    font-size: 24px;
    margin-bottom: 12px;
  }
}

.title {
  font-weight: 700;
  font-size: 34px;
  line-height: 120%;
  color: #232E23;

  @media (min-width: 1024px) {
    font-weight: 700;
    font-size: 70px;
    line-height: 140%;
  }
}

.bold {
  font-weight: 700;
}

.item {
  display: flex;
  align-items: center;
  //justify-content: space-between;
  flex-direction: column-reverse;
  margin-bottom: 40px;

  @media (min-width: 1024px) {
    flex-direction: row;
    margin-bottom: 80px;
    gap: 62px;
  }
}

.item:nth-child(2) {
  @media (max-width: 1023px) {
    flex-direction: column;
  }
}

.image {
  //max-width: 300px;

  @media (min-width: 375px) {
    max-width: 347px;
    max-height: 190px;
  }

  @media (min-width: 1024px) {
    max-width: 100%;
    max-height: 100%;
  }
}

.img1 {
  width: 130px;

  @media (min-width: 1024px) {
    width: 100%;
  }
}

.img2 {
  width: 150px;

  @media (min-width: 1024px) {
    width: 100%;
  }
}

.wrapperText {
  max-width: 347px;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    max-width: 404px;
    margin-bottom: 0;
  }
}

.wrapperTextSecond {
  max-width: 347px;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    max-width: 418px;
    margin-bottom: 0;
  }
}

.header {
  letter-spacing: 0.02em;
  color: #000000;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 10px;

  @media (min-width: 1024px) {
    font-size: 34px;
    line-height: 120%;
    margin-bottom: 14px;
  }
}

.text {
  color: #181818;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  @media (min-width: 1024px) {
    font-weight: 600;
    font-size: 22px;
    line-height: 150%;
  }
}

.wrapperBlock {
  display: flex;
  gap: 12px;
  margin-top: 20px;

  @media (min-width: 1024px) {
    margin-top: 32px;
  }
}

.wrapperButton {
  text-align: center;
}

.button {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: url('/images/aboutUs/arrow22.svg') 95% 45% no-repeat, #4FAF4D;
  border-radius: 100px;
  padding: 17px 34px 16px;

  @media (min-width: 375px) {
    padding: 17px 50px 16px;
  }

  @media (min-width: 1024px) {
    padding: 22px 58px 21px;
    font-size: 20px;
    line-height: 130%;
  }
}

.button:hover {
  background: #53CC51;
}

.button:active {
  background: #47A245;
}

//.none {
//  opacity: 0;
//}
//
//.activeBlock {
//  opacity: 1;
//
//}
//
//.activeBlock1 {
//  opacity: 1;
//  transition: 2s;
//  animation: showDiv 3s forwards;
//}
//
//.activeBlock2 {
//  opacity: 1;
//  transition: 4s;
//  animation: showDiv2 3s forwards;
//}
//
//@keyframes showDiv {
//  0%,
//  50% {
//    opacity: 0;
//  }
//  100% {
//    opacity: 1;
//  }
//}
//
//@keyframes showDiv2 {
//  0%,
//  85% {
//    opacity: 0;
//  }
//  100% {
//    opacity: 1;
//  }
//}